<template>
    <div class="main-dask-company-page">
        <button class="small-mode-buttom"
            :class="{
                'showing-small-mode-button': showingLeftbuttonSmallMode
            }"
            @click="clickLeftButtonSmallMode"
        >&laquo;</button>
        <div class="element-place"
            :class="{
                'showing-small-mode': flagShowLeftSmaleMode,
                'hiding-small-mode': !flagShowLeftSmaleMode
            }"
        >   
            <Transition name="fade" mode="out-in">
                <component
                    :is="leftColumn"
                    :nameColumn="leftPartData.nameColumn"
                    :additionalName="additionalName"
                    :flagCreateForm="flagCreateForm"
                    :userOptions="userOptions"
                    @clickCreateLine="clickCreateLine"
                    :lineList="workList"
                    :choosedLineId="leftPartData.choosedCompanyId"
                    :flagShowBack="leftPartData.nameColumn == 'Конечные потребители'"
                    @clickBack="backToDealerForm"
                    @clickLine="chooseElementList"
                    :key="leftPartData.key"


                    
                    :nameElement="middlePart.nameElement"
                    :shortNameElement="middlePart.shortNameElement"
                    :nameElementForm="middlePart.nameForm"
                    :typeForm="typeMiddleForm"
                    :typeElement="leftPartData.nameColumn"
                    :idDealer="middlePart.choosedDealerParam.id"
                    :idCompany="leftPartData.choosedCompanyId"
                    :flagChangeForm="middlePart.changeFlagUser"
                    :paramUserChangePassword="middlePart.changeUserParam"
                    :roleUser="userOptions.role"
                    @clickChangePassword="changePassword"
                    @closeFormChangePassword="closeChangeUserForm"
                    @createElement="createElementLine"
                    @closeCreateForm="clickCreateLine"
                    @changeDealer="changeDealerLine"
                    @changeCompany="changeCompanyLine"
                    @addNewUser="addNewElementUser"
                    @clickDeleteDealer="deleteDealer"
                    @clickDeleteCompany="deleteCompany"

                    @specialBackToList="backClickOfList"
                    @showConsumerList="showConsumerList"
                    :isShowedSpecialtis="true"
                />
            </Transition>
        </div>
        <div class="middle-column"
            :class="{
                'showing-small-mode': flagShowMiddleSmaleMode,
                'hiding-small-mode': !flagShowMiddleSmaleMode
            }"
        >
            <Transition name="fade" mode="out-in">
                <component
                    :is="middleColumn"
                    :nameElement="middlePart.nameElement"
                    :shortNameElement="middlePart.shortNameElement"
                    :nameElementForm="middlePart.nameForm"
                    :typeForm="typeMiddleForm"
                    :typeElement="leftPartData.nameColumn"
                    :idDealer="middlePart.choosedDealerParam.id"
                    :idCompany="leftPartData.choosedCompanyId"
                    :flagChangeForm="middlePart.changeFlagUser"
                    :paramUserChangePassword="middlePart.changeUserParam"
                    :roleUser="userOptions.role"
                    :key="middlePart.key"
                    @clickChangePassword="changePassword"
                    @closeFormChangePassword="closeChangeUserForm"
                    @createElement="createElementLine"
                    @closeCreateForm="clickCreateLine"
                    @changeDealer="changeDealerLine"
                    @changeCompany="changeCompanyLine"
                    @addNewUser="addNewElementUser"
                    @clickDeleteDealer="deleteDealer"
                    @clickDeleteCompany="deleteCompany"

                    
                    :userList="userList"
                    @deleteUser="deleteUserForm"
                    @changeUser="openFormChangeUser"


                    :isShowedSpecialtis="false"
                />
            </Transition>
        </div>
        <div class="right-column"
            :class="{
                'showing-small-mode': flagShowRightSmaleMode,
                'hiding-small-mode': !flagShowRightSmaleMode
            }"
        >
            <Transition name="fade" mode="out-in">
                <component
                    :is="rightColumn"
                    :userList="userList"
                    :key="rightPart.key"
                    @deleteUser="deleteUserForm"
                    @changeUser="openFormChangeUser"
                />
            </Transition>
        </div>
        <button class="small-mode-buttom"
            :class="{
                'showing-small-mode-button': showingRightbuttonSmallMode
            }"
            @click="clickRighButtonSmallMode"
        >&raquo;</button>
    </div>
</template>

<script>
import { shallowRef } from 'vue'
import { mapGetters, mapActions } from 'vuex'

import { useWindowSize } from '@vueuse/core'

import CreateDealerCompanyForm from '@/components/Simple_elements/CreateDealerCompanyForm.vue'
import CompanyList from '@/components/Simple_elements/CompanyList.vue'
import DealerCompanyForm from '@/components/Simple_elements/DealerCompanyForm.vue'
import UsersListForm from './UsersListForm.vue'

const { width } = useWindowSize()

export default {
    name: 'Company',
    computed: {
        ...mapGetters({
            userOptions: 'getUserOptions',
            dealerList: 'getDealerListByCompanyPage',
            userList: 'getUserListByCompanyPage'
        }),
        workList() {
            if (this.leftPartData.nameColumn == 'Дилеры') {
                return this.dealerList
            } else if (this.leftPartData.nameColumn == 'Конечные потребители') {
                return this.leftPartData.companyList
            } else {
                return []
            }
        },
        additionalName() {
            if (this.leftPartData.choosedDealerName == '') {
                return ''
            } else {
                return '(' +this.leftPartData.choosedDealerName + ')'
            }
        },
        typeMiddleForm() {
            return this.leftPartData.choosedCompanyId==0?'dealer':'company'
        }
    },
    data() {
        return {
            searchingLine: '',
            windowWidth: window.innerWidth,
            showingLeftbuttonSmallMode: false,
            showingRightbuttonSmallMode: false,
            flagShowLeftSmaleMode: false,
            flagShowMiddleSmaleMode: false,
            flagShowRightSmaleMode: false,
            flagShowCompany: false,
            flagCreateForm: false,
            leftColumn: shallowRef(CompanyList),
            middleColumn: shallowRef(),
            rightColumn: shallowRef(),
            leftPartData: {
                nameColumn: 'Дилеры',
                choosedDealerName: '',
                choosedCompanyId: 0,
                companyList: [],
                key: 0
            },
            middlePart: {
                changeUserParam: {},
                choosedDealerParam: {},
                changeFlagUser: false,
                nameForm: '',
                nameElement: '',
                shortNameElement: '',
                key: 0
            },
            rightPart: {
                userList: [],
                key: 0
            }
        }
    },
    components: {
        CreateDealerCompanyForm,
        CompanyList,
        DealerCompanyForm,
        UsersListForm
    },
    methods: {
        ...mapActions({
            getDealerList: 'getApiDealerListByCompanyPage',
            addDealerToList: 'postApiDealerListByCompanyPage',
            changeDealerParam: 'putApiDealerListByCompanyPage',
            deleteDealerList: 'deleteApiDealerListByCompanyPage',
            addCompanyToList: 'postApiCompanyListByCompanyPage',
            changeCompanyParam: 'putApiCompanyListByCompanyPage',
            deleteCompanyList: 'deleteApiCompanyListByCompanyPage',
            getUserList: 'getApiUserListByCompanyPage',
            addUserPost: 'postApiUserByCompanyPage',
            deleteUser: 'deleteApiUserByCompanyPage',
            changeUser: 'putApiUserByCompanyPage'
        }),
        showConsumerList() {
            this.leftColumn = shallowRef(CompanyList);
            this.middleColumn = shallowRef();
            this.rightColumn = shallowRef();
            this.showingLeftbuttonSmallMode = false;
            this.showingRightbuttonSmallMode = false;
        },
        async deleteUserForm(param) {
            if (confirm('Удалить пользователя "' + param.name +'"')) {
                await this.deleteUser(param.id)
                let companies = []
                this.dealerList.forEach(el=>{
                    if (el.id==this.middlePart.choosedDealerParam.id) {
                        companies = el.companies
                    }
                })
                this.leftPartData.companyList = companies
            }
        },
        changePassword(param) {
            let options = {
                ...param,
                company_id: this.leftPartData.choosedCompanyId==0?null:this.leftPartData.choosedCompanyId,
                dealer_id: this.middlePart.choosedDealerParam.id,
                username: this.middlePart.choosedDealerParam.name
            }
            this.changeUser(options)
        },
        openFormChangeUser(param) {
            this.middlePart.changeFlagUser = true
            if (this.leftPartData.choosedCompanyId == 0) {
                this.middlePart.changeUserParam = param
                this.middlePart.changeUserParam.type = 'dealer'
                // alert('change dealer')
            } else {
                this.middlePart.changeUserParam = param
                this.middlePart.changeUserParam.type = 'company'
                // alert('change company')
            }
            console.log(this.middlePart.changeUserParam)
        },
        closeChangeUserForm() {
            this.middlePart.changeFlagUser = false
        },
        async addNewElementUser(options) {
            await this.addUserPost(options)
            let companies = []
            this.dealerList.forEach(el=>{
                if (el.id==this.middlePart.choosedDealerParam.id) {
                    companies = el.companies
                }
            })
            this.leftPartData.companyList = companies
        },
        clickCreateLine() {
            if (this.flagCreateForm == true) {
                this.middleColumn = shallowRef()
                this.flagCreateForm = false
                this.flagShowLeftSmaleMode = true;
                this.flagShowMiddleSmaleMode = false;
                this.flagShowRightSmaleMode = false;
                if (this.leftPartData.nameColumn == 'Конечные потребители') {
                    
                    this.showingRightbuttonSmallMode = true;
                    this.showingLeftbuttonSmallMode = false;
                    this.middleColumn = shallowRef(DealerCompanyForm)
                    this.rightColumn = shallowRef(UsersListForm)
                } else {
                    this.showingRightbuttonSmallMode = false;
                    this.showingLeftbuttonSmallMode = false;
                }
            } else {
                this.chooseElementList({id: 0})
                
                this.flagShowLeftSmaleMode = false;
                this.flagShowMiddleSmaleMode = true;
                this.flagShowRightSmaleMode = false;
                this.showingRightbuttonSmallMode = false;
                this.showingLeftbuttonSmallMode = false;
                this.middleColumn = shallowRef(CreateDealerCompanyForm)
                this.rightColumn = shallowRef()
                this.flagCreateForm = true
            }
        },
        async createElementLine(nameElement, shortNameElement, typeElement) {
            let options = {
                name: shortNameElement,
                showing_name: nameElement
            }
            this.flagShowLeftSmaleMode = true;
            this.flagShowMiddleSmaleMode = false;
            this.flagShowRightSmaleMode = false;
            if (typeElement == "Дилеры") {
                this.addDealerToList(options)
                // this.chooseElementList({id: 0})
                this.flagCreateForm = false
                this.middleColumn = shallowRef()
                this.rightColumn = shallowRef()
            } else {
                this.showingRightbuttonSmallMode = true;
                this.showingLeftbuttonSmallMode = false;
                options.dealer_id = this.middlePart.choosedDealerParam.id
                await this.addCompanyToList(options)
                this.leftPartData.companyList = this.dealerList.filter(el=> {
                    if (el.id == this.middlePart.choosedDealerParam.id) {
                        return true
                    } else {
                        return false
                    }
                })[0].companies
                this.flagCreateForm = false
                this.middleColumn = shallowRef()
                this.rightColumn = shallowRef()
            }
        },
        async changeDealerLine(options) {
            await this.changeDealerParam(options);
            this.dealerList.forEach(el=>{
                if (el.id == options.id) {
                    // console.log()
                    this.middlePart.nameElement = el.showing_name
                    this.middlePart.shortNameElement = el.name
                    this.middlePart.nameForm = "Форма дилера " + el.showing_name
                    this.leftPartData.choosedDealerName = el.showing_name
                }
            })
        },
        async deleteDealer(options) {
            await this.deleteDealerList(options)
            this.flagShowLeftSmaleMode = true;
            this.flagShowMiddleSmaleMode = false;
            this.flagShowRightSmaleMode = false;
            this.showingRightbuttonSmallMode = false;
            this.showingLeftbuttonSmallMode = false;
            this.leftPartData.nameColumn = "Дилеры"
            this.leftPartData.choosedDealerName = ""
            this.leftPartData.choosedCompanyId = 0
            this.leftPartData.companyList = []
            this.middlePart.choosedDealerParam = {}
            this.leftColumn = shallowRef(CompanyList)
            this.middleColumn = shallowRef()
            this.rightColumn = shallowRef()
        },
        async deleteCompany(options) {
            await this.deleteCompanyList(options)
            this.dealerList.forEach(el => {
                if (this.middlePart.choosedDealerParam.id == el.id) {
                    this.leftPartData.companyList = el.companies
                }
            })
            this.middleColumn = shallowRef()
            this.rightColumn = shallowRef()
            this.middlePart.nameForm = "Форма дилера " + this.middlePart.choosedDealerParam.name
            this.middlePart.nameElement = this.middlePart.choosedDealerParam.name
            this.middlePart.shortNameElement = this.middlePart.choosedDealerParam.shortName
            
            this.getUserList({
                id: this.middlePart.choosedDealerParam.id,
                type: 'dealer'
            })
        },
        async changeCompanyLine(options) {
            await this.changeCompanyParam(options);
            this.dealerList.forEach(el => {
                if (options.param.dealer_id == el.id) {
                    this.leftPartData.companyList = el.companies
                }
            })
            this.leftPartData.companyList.forEach(el=>{
                this.middlePart.nameElement = el.showing_name
                this.middlePart.shortNameElement = el.name
                this.middlePart.nameForm = "Форма конечного потребителя " + el.showing_name
            })
        },
        backToDealerForm() {
            this.chooseElementList({id: 0})
            this.showingLeftbuttonSmallMode = false;
            this.showingRightbuttonSmallMode = true;
            this.leftColumn = shallowRef(DealerCompanyForm);
            this.middleColumn = shallowRef(UsersListForm);
            this.rightColumn = shallowRef();

        },
        chooseElementList(paramEl) {
            if (this.leftPartData.nameColumn == 'Дилеры') {
                this.showingRightbuttonSmallMode = true;
                this.showingLeftbuttonSmallMode = false;
                if (paramEl.id) {
                    this.leftColumn = shallowRef(DealerCompanyForm)
                    this.middleColumn = shallowRef(UsersListForm)
                    if(this.flagCreateForm == true) {
                        this.clickCreateLine()
                    }
                    this.leftPartData.companyList = paramEl.companies
                    this.leftPartData.nameColumn = 'Конечные потребители'
                    this.leftPartData.choosedDealerName = paramEl.name
                    this.middlePart.nameForm = "Форма дилера " + paramEl.name
                    this.middlePart.nameElement = paramEl.name
                    this.middlePart.shortNameElement = paramEl.shortName
                    this.middlePart.key = 1

                    this.middlePart.choosedDealerParam = paramEl
                    // console.log()
                    this.getUserList({
                        id: paramEl.id,
                        type: 'dealer'
                    })
                    this.rightPart.key = 1
                }
            } else {
                if (paramEl.id) {
                    this.showingLeftbuttonSmallMode = false;
                    this.showingRightbuttonSmallMode = true;
                    this.leftPartData.choosedCompanyId = paramEl.id
                    if(this.flagCreateForm == true) {
                        this.clickCreateLine()
                    }
                    this.getUserList({
                        id: paramEl.id,
                        type: 'company'
                    })
                    this.middleColumn = shallowRef(DealerCompanyForm)
                    this.rightColumn = shallowRef(UsersListForm)
                    this.middlePart.nameForm = "Форма конечного потребителя " + paramEl.name
                    this.middlePart.nameElement = paramEl.name
                    this.middlePart.key = 2
                    this.middlePart.shortNameElement = paramEl.shortName
                    this.rightPart.key = 2
                } else {
                    this.showingLeftbuttonSmallMode = false;
                    this.showingRightbuttonSmallMode = false;
                    this.middleColumn = shallowRef()
                    this.rightColumn = shallowRef()
                    this.middlePart.key = 0
                    this.rightPart.key = 0
                    this.leftPartData.choosedCompanyId = 0
                    this.getUserList({
                        id: this.middlePart.choosedDealerParam.id,
                        type: 'dealer'
                    })
                    this.middlePart.nameForm = "Форма дилера " + this.middlePart.choosedDealerParam.name
                    this.middlePart.nameElement = this.middlePart.choosedDealerParam.name
                    this.middlePart.shortNameElement = this.middlePart.choosedDealerParam.shortName
                }
            }
        },
        chooseElementListOld(paramEl) {
            this.middlePart.changeFlagUser = false
            if (this.leftPartData.nameColumn == 'Дилеры') {
                this.showingRightbuttonSmallMode = true;
                this.showingLeftbuttonSmallMode = false;
                if (paramEl.id) {
                    if(this.flagCreateForm == true) {
                        this.clickCreateLine()
                    }
                    this.leftPartData.companyList = paramEl.companies
                    this.leftPartData.nameColumn = 'Конечные потребители'
                    this.leftPartData.choosedDealerName = paramEl.name
                    this.middleColumn = shallowRef(DealerCompanyForm)
                    this.middlePart.nameForm = "Форма дилера " + paramEl.name
                    this.middlePart.nameElement = paramEl.name
                    this.middlePart.shortNameElement = paramEl.shortName
                    this.middlePart.key = 1

                    this.middlePart.choosedDealerParam = paramEl
                    // console.log()
                    this.getUserList({
                        id: paramEl.id,
                        type: 'dealer'
                    })
                    this.rightColumn = shallowRef(UsersListForm)
                    this.rightPart.key = 1
                } else {
                    this.leftPartData.companyList = []
                    this.leftPartData.nameColumn = 'Дилеры'
                    this.middlePart.nameForm = ''
                    this.middlePart.nameElement = ''
                    this.middlePart.shortNameElement = ''
                    this.leftPartData.choosedDealerName = ''
                    this.middlePart.key = 0
                    this.middlePart.choosedDealerParam = {}
                    this.middleColumn = shallowRef()
                    this.rightColumn = shallowRef()
                    this.rightPart.key = 0
                }
            } else {
                if (paramEl.id) {
                    this.leftPartData.choosedCompanyId = paramEl.id
                    if(this.flagCreateForm == true) {
                        this.clickCreateLine()
                    }
                    this.getUserList({
                        id: paramEl.id,
                        type: 'company'
                    })
                    this.middleColumn = shallowRef(DealerCompanyForm)
                    this.middlePart.nameForm = "Форма конечного потребителя " + paramEl.name
                    this.middlePart.nameElement = paramEl.name
                    this.middlePart.key = 2
                    this.middlePart.shortNameElement = paramEl.shortName
                    this.rightPart.key = 2
                } else {
                    this.middlePart.key = 0
                    this.rightPart.key = 0
                    this.leftPartData.choosedCompanyId = 0
                    this.getUserList({
                        id: this.middlePart.choosedDealerParam.id,
                        type: 'dealer'
                    })
                    this.middlePart.nameForm = "Форма дилера " + this.middlePart.choosedDealerParam.name
                    this.middlePart.nameElement = this.middlePart.choosedDealerParam.name
                    this.middlePart.shortNameElement = this.middlePart.choosedDealerParam.shortName
                }
            }
        },
        backClickOfList() {
            this.leftColumn = shallowRef(CompanyList)
            this.showingRightbuttonSmallMode = false;
            this.showingLeftbuttonSmallMode = false;
            this.leftPartData.nameColumn = 'Дилеры'
            this.leftPartData.choosedCompanyId = 0
            this.leftPartData.companyList = []
            this.leftPartData.choosedDealerName = ''
            if (this.middleColumn && this.middleColumn.name == 'CreateDealerCompanyForm') {
                this.clickCreateLine()
            } else {
                this.middleColumn = shallowRef()
            }
            this.rightColumn = shallowRef()
        },
        clickRighButtonSmallMode() {
            if (!this.showingLeftbuttonSmallMode && this.showingRightbuttonSmallMode) {
                this.showingLeftbuttonSmallMode = true;
                if (this.middlePart.nameForm.indexOf('Форма дилера') == -1) {
                    this.showingRightbuttonSmallMode = true;
                } else {
                    this.showingRightbuttonSmallMode = false;
                }
                this.flagShowLeftSmaleMode = false;
                this.flagShowMiddleSmaleMode = true;
                this.flagShowRightSmaleMode = false;
            } else if (this.showingLeftbuttonSmallMode && this.showingRightbuttonSmallMode) {
                this.showingLeftbuttonSmallMode = true;
                this.showingRightbuttonSmallMode = false;
                this.flagShowLeftSmaleMode = false;
                this.flagShowMiddleSmaleMode = false;
                this.flagShowRightSmaleMode = true;
            }
        },
        clickLeftButtonSmallMode() {
            if (this.showingLeftbuttonSmallMode && this.showingRightbuttonSmallMode) {
                this.showingLeftbuttonSmallMode = false;
                this.showingRightbuttonSmallMode = true;
                this.flagShowLeftSmaleMode = true;
                this.flagShowMiddleSmaleMode = false;
                this.flagShowRightSmaleMode = false;
            } else if (this.showingLeftbuttonSmallMode && !this.showingRightbuttonSmallMode) {
                if (this.middlePart.nameForm.indexOf('Форма дилера') == -1) {
                    this.showingLeftbuttonSmallMode = true;
                    this.showingRightbuttonSmallMode = true;
                    this.flagShowLeftSmaleMode = false;
                    this.flagShowMiddleSmaleMode = true;
                    this.flagShowRightSmaleMode = false;
                } else {
                    this.showingLeftbuttonSmallMode = false;
                    this.showingRightbuttonSmallMode = true;
                    this.flagShowLeftSmaleMode = true;
                    this.flagShowMiddleSmaleMode = false;
                    this.flagShowRightSmaleMode = false;
                }
            }
        }
    },
    created() {
        this.getDealerList();
        this.flagShowLeftSmaleMode = true;
        this.showingRightbuttonSmallMode = false;
        this.showingLeftbuttonSmallMode = false;
        // this.leftPartData.showedList = this.dealerList;
    },
    beforeUnmount() {
    }
}
</script>

<style noscoped lang="scss">
.main-dask-company-page{
    height: 93vh;
    padding: 32px 1vw;
    overflow-y: auto;
    display: flex;

    .small-mode-buttom {
        display: none;
        width: 3vw;
        opacity: 0;
        background: none;
        font-size: 20px;
        border: 1px solid black;
        border-radius: 4px;
        transition: all .4s ease;

        &:hover {
            background: rgba(0, 0, 0, 0.049);
        }

        &:active {
            background: rgba(0, 0, 0, 0.116);
        }
    }

    .element-place {
        width: 40vw;
        padding: 0 10px;
        
        .control-place {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 25px;
            
            .column-name {
                color: var(--gray-dark-color);
            }

            .filter-line {
                // width: 72px;
                font-weight: 400;
                font-size: 14px;
                color: var(--gray-dark-color);
                // border: 0 solid var(--gray-dark-color);
                border-bottom: 1px solid var(--gray-dark-color);
            }
            .add-line {
                border: 1px solid var(--gray-dark-color);
                background: none;
                border-radius: 3px;
                padding: 5px 15px;
                width: 121px;
                height: 27px;
                font-family: var(--font-family);
                font-weight: 400;
                font-size: 14px;
                color: var(--black-color);
                transition: all .3s ease;
                display: flex;
                align-items: center;
                justify-content: center;
                p {
                    transition: all .3s ease;
                }

                &:hover {
                    background: #94949417;
                }

                &:active {
                    background: #9494943a;
                }
                &.hiden-button {
                    opacity: 0;
                }
            }

            .cancel-add-line {
                background: var(--red-color);
                .sign-button {
                    rotate: 45deg;
                }
                &:hover {
                    background: #f97676a9;
                }

                &:active {
                    background: #f976764d;
                }
            }
            
        }

        .el-list-place {
            display: flex;
            flex-direction: column;
        }

    }
    .middle-column {
        width: 29vw;
        padding: 0 10px;
    }
    .right-column {
        width: 29vw;
        padding: 0 10px;
    }
}

@media screen and (max-width: 1000px) {
    .main-dask-company-page {
        .hiding-small-mode {
            display: none;
        }
        .showing-small-mode {
            display: inline-block;
            width: 92vw;
        }
        .small-mode-buttom {
            display: inline-block;
        }

        .showing-small-mode-button {
            opacity: 1;
        }

        .create-dealer-form {
            .small-mode-back {
                display: inline-block;
                padding: 0;
            }
        }
        // .fade-enter-active,
        // .fade-leave-active {
        //     transition: opacity 0s ease;
        // }
    }
}

</style>