<template>
<div>
    <div class="control-place">
        <input type="text" class="filter-line" v-model="searchingLine" placeholder="Поиск...">
        <p class="plain-text column-name">{{nameColumn}} {{ additionalName }}</p>
        <button
            class="add-line"
            :class="{
                'cancel-add-line': flagCreateForm,
                'hiden-button': userOptions.role != 'developer' && userOptions.role != 'admin' && nameColumn == 'Дилеры'
            }"
            @click="clickCreateLine"
            :disabled="
                // leftPartData.nameColumn == 'Компании'
                userOptions.role != 'developer' && userOptions.role != 'admin' && nameColumn == 'Дилеры'
            "
        >
            <p class="sign-button">+</p>
            <p class="text-button" v-if="!flagCreateForm">&nbsp;Добавить</p>
            <p class="text-button" v-if="flagCreateForm">&nbsp;Отменить</p>
        </button>
    </div>
    <div class="el-list-place">
        <button class="back-button-list" v-if="flagShowBack" @click="onBackClick">назад</button>
        <CompanyLine v-for="item in workList" :key="item.id"
            :name="item.showing_name"
            :shortName="item.name"
            :usersNumber="item.count_users"
            :flagChoose="choosedLineId==item.id"
            @closeChoose="chooseLine({id:0})"
            @click="chooseLine({
                id: item.id,
                name: item.showing_name,
                shortName: item.name,
                companies: item.companies
            })"
        />
    </div>
</div>
</template>
<script>

import CompanyLine from '@/components/Simple_elements/CompanyLine.vue'

export default {
    name: 'CompanyList',
    props: ['lineList', 'choosedLineId', 'flagShowBack',
            'nameColumn', 'additionalName', 'flagCreateForm', 'userOptions'],
    emits: ['clickLine', 'clickBack',
            'clickCreateLine'],
    computed: {
        workList() {
            return this.lineList?this.lineList.filter((el)=>{
                if (
                    el.showing_name.toLowerCase().indexOf(this.searchingLine.toLowerCase()) != -1 ||
                    this.searchingLine == ''
                ) {
                    return true;
                } else {
                    return false;
                }
            }):[]
        }
    },
    data() {
        return {
            searchingLine: ''
        }
    },
    components: {
        CompanyLine,
    },
    methods: {
        chooseLine(paramEl) {
            this.$emit('clickLine', paramEl)
        },
        onBackClick() {
            this.$emit('clickBack')
        },
        clickCreateLine() {
            this.$emit('clickCreateLine')
        }
    },
    created() {
    },
    beforeUnmount() {
    }
}
</script>

<style noscoped lang="scss">
    .el-list-place {
        .back-button-list {
            background: var(--gray-color);
            border: 1px solid var(--gray-dark-color);
            border-radius: 3px;
            transition: all .3s ease;

            &:hover {
                background: rgba(175, 174, 174, 0.582);
            }
            &:active {
                background: rgb(138, 138, 138, 0.582);
            }
        }
    }
    // .company-line {
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     border: 1px solid var(--gray-dark-color);
    //     border-radius: 4px;
    //     padding: 5px 14px;
    //     transition: all .2s ease;

    //     &:not(:first-child) {
    //         margin-top: 12px;
    //     }

    //     &:hover {
    //         background: #94949417;
    //     }

    //     &:active {
    //         background: #9494943a;
    //     }

    //     .data-part {
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: space-between;
    //         align-items: start;

    //         p {
    //             color: var(--gray-dark-color);
    //         }
    //     }
    //     button {
    //         display: flex;
    //         background: none;
    //         cursor: pointer;
    //     }
    // }
    // .company-line.choosed {
    //     background: var(--gray-dark-color);

    //     .data-part {
    //         p {
    //             color: var(--gray-color);
    //         }
    //     }
    // }
</style>